<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div class="container background-with-svg">
    <div class="subContainer">
      <span class="text">Coming soon</span>
    </div>
    <div class="subContainer">
      <!-- <span class="title">Monika Schriber</span>
      <span class="title separator">-</span> -->
      <span class="title">SuJok Therapie</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
@import "./assets/css/fonts.css";

#app {
  flex: 1;
  font-family: alumni, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  padding:0;
  margin:0;
  justify-content: center;
  align-content: center;
  background-color: #ff7400;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #ff7400;
  justify-content: center;
  align-content: center;
  text-align: center;
  border: none;
  outline: none;
}
.subContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.title {
  font-size: 72pt;
  color: #ffffff;
  user-select: none;
  cursor: auto;
}
.separator {
  margin-left: 5px;
  margin-right: 5px;
}
.text {
  flex: 1;
  font-size: 62pt;
  color: #ffffff;
  user-select: none;
  cursor: auto;
}

.background-with-svg {
  background: url("./assets/pattern.svg") repeat;
  background-color: #ff7400;
  border: none;
  outline: none;
  border-width: 0;
}

@media screen and (max-width: 768px) {
  .subContainer {
    flex-direction: column; /* Stacks spans vertically on small screens */
  }
  .title {
    font-size: 42pt;
  }
  .text {
    font-size: 36pt;
  }
}
</style>
